// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-programlanding-js": () => import("./../../../src/pages/programlanding.js" /* webpackChunkName: "component---src-pages-programlanding-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-content-section-landing-js": () => import("./../../../src/templates/content_section_landing.js" /* webpackChunkName: "component---src-templates-content-section-landing-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-parttime-js": () => import("./../../../src/templates/parttime.js" /* webpackChunkName: "component---src-templates-parttime-js" */),
  "component---src-templates-program-type-landing-js": () => import("./../../../src/templates/program_type_landing.js" /* webpackChunkName: "component---src-templates-program-type-landing-js" */)
}

